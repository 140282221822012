<script>
import appConfig from "../../../app.config";
import NavMenu from "../landing/inc/nav-menu.vue";
import Footer from "../landing/inc/footer.vue";
import {
  required,
  email,
  helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axios from 'axios';

export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  page: {
    title: "Register",
    meta: [{
      name: "description",
      content: appConfig.description,
    },],
  },
  components: {
    NavMenu,
    Footer
  },
  data() {
    return {
      mode : 'form',
      form: {
        type : "daon-shop",
        name : "",
        company : "",
        email : "",
        phone : "",
        content : "",
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations: {
    form: {
      name : {
        required: helpers.withMessage("이름을 입력해주세요.", required),
      },
      email : {
        required: helpers.withMessage("이메일을 입력해주세요.", required),
        email: helpers.withMessage("잘못된 이메일 형식입니다.", email),
      },
      phone : {
        required: helpers.withMessage("연락처를 입력해주세요.", required),
      },
      content : {
        required: helpers.withMessage("문의내용을 입력해주세요.", required),
      }
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {

    async tryToRegisterIn() {
      let the = this;
      this.submitted = true;

      this.v$.$touch();

      axios.defaults.baseURL = process.env.VUE_APP_API_URL;
      axios.post('/contactus/store', {
        name : this.form.name,
        company : this.form.company,
        phone : this.form.phone,
        email : this.form.email,
        content : this.form.content,
        type : this.form.type
      }).then((response) => {

        if (response.data.status == 'success') {
            this.mode = 'result';
        }

      }).catch(function (error) {
        if (error.response) {

            //if(error.response.data.errors.length > 0) {
                the.isRegisterError = true;
                return the.regError = error.response.data.message;
            //}

            //console.log('error response', error.response.data);
        }
        else {
            console.log('error message', error.message);
        }
        
      })
    },
  },
};
</script>
<template>
    <div class="layout-wrapper">

        <nav-menu></nav-menu>

        <div class="bg-overlay bg-overlay-pattern"></div>

        <section class="section header">
            <div class="bg-overlay"></div>
        </section>

        <section class="section">
            <b-container>

                <b-row class="justify-content-center mt-5">
                    <b-col lg="5">
                        <div class="text-center mb-4 pb-2">
                        <h4 class="fw-semibold fs-22">
                            다온코드 개발문의
                        </h4>
                        <p class="text-muted mb-4 fs-15">
                            인쇄쇼핑몰, 인쇄MES, 인쇄ERP, 운영/유지보수, 기타 맞춤개발문의
                        </p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="justify-content-center">
                    <b-col md="12" lg="10" xl="8">

                        <b-card no-body class="mt-4" v-if="mode == 'form'">

                            <b-card-body class="p-4">
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">개발문의시 24시간 이내 답변드립니다.</h5>
                                    <h5 class="text-primary">문의내용은 상세하게 작성할수록 상담에 많은 도움이 됩니다.</h5>
                                </div>
                                <div class="p-2 mt-4">

                                    <form class="needs-validation" @submit.prevent="tryToRegisterIn">
                                        <b-alert v-model="registerSuccess" class="mt-3" variant="success" dismissible>개발문의 성공.</b-alert>

                                        <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                                        </b-alert>

                                        <div v-if="notification.message" :class="'alert ' + notification.type">
                                        {{ notification.message }}
                                        </div>

                                        <div class="mb-3">
                                            <b-row class="mb-2">
                                                <b-col cols="3">문의유형 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <select class="form-select" v-model="form.type">
                                                        <option value="daon-shop" selected>인쇄쇼핑몰 제작문의</option>
                                                        <option value="daon-mes">MES/POP 개발문의</option>
                                                        <option value="daon-erp">ERP/CRM 개발문의</option>
                                                        <option value="daon-maintenance">운영/유지보수 상담</option>
                                                        <option value="etc">기타 맞춤 웹개발 상담</option>
                                                    </select>

                                                </b-col>
                                            </b-row>
                                        </div>

                                        <div class="mb-3">
                                            <b-row class="mb-2">
                                                <b-col cols="3">이름 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <input type="text" class="form-control" v-model="form.name" :class="{
                                                        'is-invalid': submitted && v$.form.name.$error,
                                                    }" placeholder="이름을 입력해주세요.">
                                                    <div v-for="(item, index) in v$.form.name.$errors" :key="index" class="invalid-feedback">
                                                        <span v-if="item.$message">{{ item.$message }}</span>
                                                    </div>

                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="3">회사명</b-col>
                                                <b-col cols="9">

                                                    <input type="text" class="form-control" v-model="form.company" placeholder="회사명을 입력해주세요.">

                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="3">연락처 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <input type="text" class="form-control" v-model="form.phone" :class="{
                                                        'is-invalid': submitted && v$.form.phone.$error,
                                                    }" placeholder="연락처를 입력해주세요.">
                                                    <div v-for="(item, index) in v$.form.phone.$errors" :key="index" class="invalid-feedback">
                                                        <span v-if="item.$message">{{ item.$message }}</span>
                                                    </div>

                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="3">이메일 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <input type="email" class="form-control" v-model="form.email" :class="{
                                                        'is-invalid': submitted && v$.form.email.$error,
                                                    }" placeholder="이메일을 입력해주세요.">
                                                    <div v-for="(item, index) in v$.form.email.$errors" :key="index" class="invalid-feedback">
                                                        <span v-if="item.$message">{{ item.$message }}</span>
                                                    </div>

                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="3">문의내용 <span class="text-danger">*</span></b-col>
                                                <b-col cols="9">

                                                    <textarea rows="10" class="form-control" v-model="form.content" :class="{
                                                        'is-invalid': submitted && v$.form.content.$error,
                                                    }" placeholder="문의내용을 입력해주세요."></textarea>

                                                </b-col>
                                            </b-row>                                        
                                        </div>

                                        <div class="mt-4 text-center">
                                            <b-button variant="danger" size="lg" class="waves-effect waves-light" type="submit">문의하기</b-button>
                                        </div>

                                        <div class="mt-4 text-center">
                                            <div class="signin-other-title">
                                                <h5 class="fs-13 mb-4 title text-muted">
                                                    <p class="text-muted">최선을 다해 개발상담해 드릴 것을 약속드립니다.</p>
                                                    <p class="text-muted">감사합니다.</p>
                                                </h5>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </b-card-body>

                        </b-card>

                        <b-card no-body class="mt-4" v-if="mode == 'result'">

                            <b-card-body class="p-4">
                                <div class="p-2 mt-4">
                                    <div class="text-center mt-2">
                                        <h5 class="text-primary">성공적으로 문의가 등록되었습니다.</h5>
                                        <h5 class="text-primary">최대한 빠른 시간내에 연락드리도록 하겠습니다.</h5>
                                        <h5 class="text-primary mt-3">감사합니다.</h5>
                                    </div>
                                </div>
                            </b-card-body>

                        </b-card>

                    </b-col>
                </b-row>

            </b-container>
        </section>
        <Footer></Footer>
    </div>
</template>